<template>
    <ClientOnly>
        <div class="flex justify-center items-center lg:mt-16 mt-8 mx-0">
            <button type="button" class="swiper-prev hidden lg:block">
                <Icon name="heroicons:chevron-left-20-solid" class="text-8xl" />
            </button>

            <swiper :spaceBetween="50" :slidesPerView="1" :centeredSlides="true" :modules="modules" :navigation="navigation"
                :loop="true" :autoplay="{ delay: 5000, pauseOnMouseEnter: true }" class="max-w-full lg:mt-16 lg:p-8 p-4">
                <swiper-slide v-for="quote in testimonials"
                    class="p-8 border-[#7B8088] border rounded-xl shadow-[0_3px_10px_rgb(43,47,168,0.82)] quote-slider">
                    <div class="lg:p-8 p-2">
                        <p class="text-center md:text-xl text-lg font-light">{{ quote.text }}</p>
                        <span class="text-center block mt-8 text-xl">{{ quote.author.name }} </span>
                        <span class="text-center block mt-2 text-sm" v-if="quote.company">{{ quote.company }} </span>
                    </div>
                </swiper-slide>
            </swiper>
            <button type="button" class="swiper-next hidden lg:block">
                <Icon name="heroicons:chevron-right-20-solid" class="text-8xl" />
            </button>
        </div>
    </ClientOnly>
</template>
<script setup lang="ts">
defineProps({
    testimonials: Array
})
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import { Autoplay, Navigation } from 'swiper/modules'
const modules = [Autoplay, Navigation]
const navigation = {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev"
}
</script>


<style scoped>
::v-deep(.swiper-wrapper) {
    display: flex;
    align-items: top;
}

::v-deep(.swiper) {
    margin: 0;
    padding: 1rem;
}

.quote-slider {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 2rem;
        padding: 1rem;
        background-color: #21303F;
        background-image: url("/assets/landing/quote.svg");
        color: red;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #7B8088;
        border-radius: 50%;
    }
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-wrap: pretty;
}
</style>
